<template>
  <div class="contaner_op">
    <new-header :config="headerConfig"></new-header>
    <div class="content_op">
      <vue-loadmore :on-refresh="onRefresh" :on-loadmore="onLoad" :finished="finished">
        <van-checkbox-group v-model="result">
          <div class="checkout_box" v-for="(item, index) in contentListData" :key="index">
            <div class="text_info">
              <div class="title">{{ item.contract_name }}</div>
              <div>合同编号：{{ item.unique_contract_no }}</div>
              <div>合同类型：工分合同</div>
              <div>获取时间：{{ item.create_time }}</div>
            </div>
            <div>
              <van-checkbox :name="item.contract_id"></van-checkbox>
              <div class="piont_box">
                <div class="price">{{ item.contract_shares }}</div>
                <div>工分</div>
              </div>
            </div>
          </div>
        </van-checkbox-group>
      </vue-loadmore>
      <div class="submit_btn" @click="submit">提交</div>
    </div>

    <van-dialog
      v-model="show"
      :show-confirm-button="false"
      width="85%"
      :closeOnClickOverlay="true"
    >
      <div class="cancel_box">
        <!-- <img src="https://cdn.health.healthplatform.xyz/FsweN_GNOrZLicejPFZmx0rHnVBq" /> -->
        <div class="text_box">
          <img @click="cloneBtn" src="../../assets/cloneBtn2.png" />
          <div class="message_title">温馨提示</div>
          <div class="left_text">
            <div class="piont">
              <div>已选：{{ selectContent.length }}</div>
              <div>工分：{{ shares }}</div>
            </div>
            <div class="flex_box">
              <div
                class="content_list"
                v-for="(item, index) in selectContent"
                :key="index"
              >
                <div>合同名称：{{ item.contract_name }}</div>
                <div>合同编号：{{ item.unique_contract_no }}</div>
              </div>
            </div>
          </div>
          <div class="error_box">以上合同将作变更，会被作废，是否确认</div>
          <div class="sure_btn" @click="contentBtn">确认</div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>
<script type>
import newHeader from "@/components/newHeader";
import { serviceContract_api } from "@/api/alteration";
import { Toast } from "vant";
export default {
  data() {
    return {
      headerConfig: {
        show: true,
        title: "合同选择",
      },
      show: false,
      radioConfig: {
        value: "",
        list: [
          { checked: true, title: "增值兑换", ladel: 1 },
          { checked: false, title: "分享补金兑换", ladel: 2 },
        ],
      },
      result: [],
      query: {
        page: 1,
        limit: 10,
      },
      contentListData: [], //合同列表
      selectContent: [], //选中合同
      contentList: [], //缓存数据
      shares: 0,
      finished: false,
    };
  },
  components: {
    newHeader,
  },
  created() {
    document.title = "";
    if (localStorage.getItem("contentList")) {
      this.contentList = JSON.parse(localStorage.getItem("contentList"));
      this.contentList.forEach((e) => {
        this.result.push(e.contract_id);
      });
    }
    this.getContentList();
  },
  methods: {
    submit() {
      this.selectContent = [];
      this.contentListData.forEach((e) => {
        this.result.forEach((j) => {
          if (e.contract_id == j) {
            this.selectContent.push(e);
          }
        });
      });
      this.selectContent.forEach((e) => {
        this.shares = this.shares + Number(e.contract_shares);
      });
      if (this.selectContent.length == 0) {
        Toast("请选择合同");
        return false;
      }
      this.show = true;
    },
    cloneBtn() {
      this.show = false;
    },
    // 上拉加载------------------
    onRefresh(done) {
      this.contentListData = [];
      this.finished = false;
      this.query.page = 1;
      this.getContentList();
      done();
    },

    onLoad(done) {
      console.log(1);

      this.fetch();

      this.finished = true;
      done();
    },

    fetch() {
      this.query.page++;
      this.getContentList();
    },
    // end--------------------------------
    contentBtn() {
      localStorage.setItem("contentList", JSON.stringify(this.selectContent));
      this.$router.go(-1);
    },
    getContentList() {
      serviceContract_api(this.query).then((res) => {
        if (res.code == 0) {
          this.contentListData = this.contentListData.concat(res.data);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.contaner_op {
  background-color: #f9f9fb;
  min-height: 100vh;
  width: 100%;

  .submit_btn {
    width: 450px;
    height: 80px;
    background: linear-gradient(180deg, #252324 0%, #3c3633 100%), #2f2b2b;
    border-radius: 12px 12px 12px 12px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 28px;
    color: #ffffff;
    text-align: center;
    line-height: 80px;
    position: fixed;
    bottom: 173px;
    left: 150px;
  }

  .content_op {
    margin: 21px 23px;
    padding-bottom: 30px;

    .checkout_box {
      width: 690px;
      height: 200px;
      background: #ffffff;
      border-radius: 10px 10px 10px 10px;
      padding: 25px 21px;
      box-sizing: border-box;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 24px;
      color: rgba(26, 26, 26, 0.6);
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      .piont_box {
        margin: 40px 0 0 -50px;

        .price {
          font-family: PingFang SC, PingFang SC;
          font-weight: 800;
          font-size: 32px;
          color: #ff9400;
          margin-bottom: 10px;
        }
      }

      .text_info {
        div {
          margin-bottom: 13px;
        }

        .title {
          font-family: PingFang SC, PingFang SC;
          font-weight: bold;
          font-size: 26px;
          color: #1a1a1a;
        }
      }
    }
  }

  .cancel_box {
    // width: 650px;
    height: 650px;
    background-image: url("https://cdn.health.healthplatform.xyz/FsweN_GNOrZLicejPFZmx0rHnVBq");
    background-size: 100% 100%;
    // background: linear-gradient(180deg, #A6C9FF 0%, #FFFFFF 50%);
    border-radius: 30px 30px 30px 30px;
    position: relative;
    text-align: center;
    // padding: 51px 22px 20px;
    box-sizing: border-box;

    .content_list {
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 28px;
      color: rgba(0, 0, 0, 0.8);
      line-height: 33px;
      margin-bottom: 30px;
      text-align: left;

      > div {
        margin-bottom: 10px;
      }
    }

    .error_box {
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 26px;
      color: #ff3232;
    }

    .piont {
      display: flex;
      font-family: PingFang SC, PingFang SC;
      font-weight: bold;
      font-size: 28px;
      color: #0091ff;
      margin: 30px 0 56px;

      > div {
        margin-right: 22px;
      }
    }

    img {
      width: 100%;
      height: 100%;
    }

    .text_box {
      padding-top: 20px;
      box-sizing: border-box;
      .left_text{
        margin-left:10%
      }
      .flex_box {
        height: 300px;
        overflow-y: scroll;
      }

      img {
        width: 24px;
        height: 24px;
        position: absolute;
        right: -30px;
        top: -20px;
      }

      > div {
        margin-bottom: 26px;
      }

      .message_title {
        font-family: PingFang SC, PingFang SC;
        font-weight: bold;
        font-size: 34px;
        color: #1a1a1a;
      }

      .message_text {
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 26px;
        color: rgba(0, 0, 0, 0.8);
        text-align: left;
      }

      .message_text1 {
        font-family: PingFang SC, PingFang SC;
        font-weight: bold;
        font-size: 26px;
        text-align: left;
        color: rgba(0, 0, 0, 0.8);
      }

      .sure_btn {
        width: 180px;
        height: 66px;
        background: #0091ff;
        border-radius: 50px 50px 50px 50px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 26px;
        color: #ffffff;
        text-align: center;
        line-height: 66px;
        margin-top: 40px;
        margin: 0 auto;
      }
    }
  }
}
</style>
